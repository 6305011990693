import axios from '../util/utils.js'

//商品列表
export const api_goodlist = (params)=>{ return axios.get('/goods',{params:params})}

//商品详情
export const api_gooddetails = (id,supplierId)=>{ return axios.get('/goods'+'/'+id+'?supplierId='+supplierId)}

//订单
export const api_order = (params)=>{ return axios.get('/dingosuser/orders',{params:params})}

// 获取商品SKU
export const api_goodSkuDetails = (id,supplierId)=>{ return axios.get('/goods/sku'+'/'+id+'?supplierId='+supplierId)}

//链接SUK
export const api_linkGoodSkuDetails = (params)=>{ return axios.get('/goods/sku-by-linknumber',{params:params})}

// 商品SKU详情
export const api_goodSKU = (params)=>{ return axios.get('/goods/skus',{params:params})}

// 登录入口
export const api_authoirze = (params)=>{ return axios.get('/oauth/authoirze',{params:params})}

export const api_userinfo = (params)=>{ return axios.get('/oauth/userinfo',{params:params})}

//微信支付
export const api_micropay = (params)=>{ return axios.post('wechatpay',params)}

//支付宝支付通知
export const api_alipay_notice = (params)=>{ return axios.get('/payment/micropay',{params:params})}

// jssdk
export const api_jsSdkconfig = (params)=>{ return axios.get('/oauth/jssdkconfig',{params:params})}

//微信支付通知
export const api_micropay_notice = (params)=>{ return axios.post('/payment',params)}

//接收验证码
export const api_sms_send = (params)=>{ return axios.post('/sms/send',params)}

//验证验证码
export const api_sms_check = (params)=>{ return axios.post('/sms/check',params)}

//获取鼎道授权
export let api_getAuthorization = (params)=>{ return axios.get('/dingosuser/dingos-redirect-auth',{params:params})}

//获取token
export let api_getToken= (params)=>{ return axios.get('/dingosuser/dingos-auth-token',{params:params})}

export let api_ding_goodlist = (params)=>{ return axios.get('/goods',{params:params})}

export const api_insert_visitors = (params)=>{ return axios.post('/supplier/insert-visitors',params)}
